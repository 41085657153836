/**
 * 深拷贝
 * @param {Object | Array} obj
 * @returns {Object | Array}
 */
const $deepcopy = function (obj) {
  if (!(typeof obj == "object")) {
    return;
  }
  for (var key in obj) {
    if (
      obj.hasOwnProperty.call("key") && // eslint-disable-line no-unused-vars
      (obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === "" ||
        obj[key] === "null" ||
        obj[key] === "NULL" ||
        obj[key] === "undefined")
    ) {
      delete obj[key];
    }
  }
  return JSON.parse(JSON.stringify(obj));
};
// 手机号码
const mobile = /^((13[0-9])|(14[5-9])|(15[0-3,5-9])|(17[0-9])|(18[0-9])|19[8,9])\d{8}$/;

const $time = function (begin_time, end_time){
  //年月日时分秒转换为时间戳
  let beginTime = (new Date(begin_time).getTime()) / 1000;
  let endTime = (new Date(end_time).getTime()) / 1000;
  var starttime = ''
  var endtime = ''
  if (beginTime < endTime) {
    starttime = beginTime;
    endtime = endTime;
  } else {
    starttime = endTime;
    endtime = beginTime;
  }
  //计算天数
  var timediff = endtime - starttime;
  var days = parseInt(timediff / 86400);
  //计算小时数
  // var remain = timediff % 86400;
  // var hours = parseInt(remain / 3600);
  // //计算分钟数
  // var sss = remain % 3600;
  // var mins = parseInt(sss / 60);
  var res = days
  return res;
}
export { $deepcopy,$time,mobile };
