import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
import "../src/assets/styles/mian.scss.css";
Vue.config.productionTip = false
Vue.use(ElementUI);
import * as methods from "./lib/methods";
Object.keys(methods).forEach((key) => {
  Vue.prototype[key] = methods[key];
});
// 跳转后返回顶部
router.afterEach((to, from, next) => {
  console.log(to, from, next);
  document.getElementById("app").scrollTop = 0;
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
